import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';

import * as cookie from 'js-cookie';
@UntilDestroy()
@Component({
  selector: 'hop-cookie-accept',
  template: ` @if (!acceptedCookiePolicy) {
    <div
      class="w-full rounded-md p-3 sm:mr-4 sm:mb-4 sm:max-w-sm bg-black fixed bottom-0 text-xs flex justify-between left-1/2 transform -translate-x-1/2 z-200"
    >
      <p style="color: white !important" class="text-sm">
        {{ '_cookies.this-website-uses-cookies' | translate }}

        <a class="underline" href="https://www.cookiesandyou.com/" target="_blank">{{ '_general.learn-more' | translate }}</a>
      </p>
      <div (click)="acceptCookie()" style="color: white !important;" class="text-lg cursor-pointer self-center ">
        <mat-icon>close</mat-icon>
      </div>
    </div>
  }`,
  styleUrls: ['./cookie.component.scss'],
  standalone: true,
  imports: [CommonModule, MatButtonModule, TranslateModule, MatIconModule]
})
export class CookieComponent {
  acceptedCookiePolicy = false;
  constructor() {}

  ngOnInit() {
    this.acceptedCookiePolicy = cookie.get('cookieconsent_status') === 'dismiss';
  }
  acceptCookie() {
    this.acceptedCookiePolicy = true;
    cookie.set('cookieconsent_status', 'dismiss', { expires: 400 });
  }
}
