import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, TitleStrategy, UrlSegment } from '@angular/router';
import { AuthGuard } from './_helpers';
import { TemplatePageTitleStrategy } from './title.strategy';
import { ProfileGuard } from './_helpers/profile.guard';
import { CustomLayoutPublicComponent } from './custom-layout-public/custom-layout-public.component';
import { IsProfessionalGuard } from './_helpers/is-professional.guard';
import { SlugGuard } from './_helpers/slug.guard';
import { routeDataResolver } from './app-routeDataResolver';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';

const routes: Routes = [
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [],
    children: [
      {
        path: '',
        loadChildren: () => import('./pages/pages/front/front.module').then((m) => m.FrontModule)
      },

      {
        path: 'pricing_',
        loadChildren: () => import('./pages/pages/pricing/pricing.module').then((m) => m.PricingModule),
        title: 'Pricing'
      }
    ]
  },
  {
    //component: CustomLayoutPublicComponent,
    path: '',
    canActivate: [],
    children: [
      {
        matcher: (url) => {
          if (url[0].path.match(/^@[a-zA-Z0-9][a-zA-Z0-9-_]+[a-zA-Z0-9]$/gm) && url[1]?.path === 'checkout') {
            return {
              consumed: url.slice(0, 2),
              posParams: {
                username: new UrlSegment(url[0].path.slice(1), {})
              }
            };
          }
          return null;
        },
        loadComponent: () => import('../app/pages/pages/user-checkout/user-checkout.component').then((m) => m.UserCheckoutComponent)
      }
    ]
  },
  {
    component: CustomLayoutPublicComponent,

    path: '',
    canActivate: [],
    children: [
      {
        path: 'o/:slug',
        loadChildren: () =>
          import('./pages/pages/offerAppointment-page-new/offerAppointment-page-new.module').then((m) => m.OfferAppointmentPageNewModule),
        title: 'OfferAppointment'
      },
      {
        path: 'm/:appointmentUuid',
        loadChildren: () =>
          import('./pages/pages/appointment-scheduled-appointment/appointment-scheduled-appointment.module').then(
            (m) => m.AppointmentScheduledAppointmentModule
          )
      },
      {
        path: 'p/:productUuid',
        loadChildren: () => import('./pages/pages/user-product-view/user-product-view.module').then((m) => m.UserProductViewModule)
      },
      {
        matcher: (url) => {
          if (url[0].path.match(/^@[a-zA-Z0-9][a-zA-Z0-9-_]+[a-zA-Z0-9]$/gm)) {
            return {
              consumed: url.slice(0, 1),
              posParams: {
                username: new UrlSegment(url[0].path.slice(1), {})
              }
            };
          }
          return null;
        },
        loadChildren: () => import('./pages/pages/user-public-profile/user-public-profile.module').then((m) => m.UserPublicProfileModule) // component: UserPublicProfileComponent
      }
    ]
  },
  {
    path: '',
    canActivate: [],
    children: [
      {
        path: 'login',
        loadComponent: () => import('./pages/pages/auth/page-fast-sign-in.component').then((m) => m.PageFastSignInComponent),
        title: 'Login'
      },
      {
        path: 'register',
        loadComponent: () => import('./pages/pages/auth/page-fast-sign-in.component').then((m) => m.PageFastSignInComponent),
        title: 'Register'
      },
      {
        path: 'sign-in',
        loadComponent: () => import('./pages/pages/auth/page-fast-sign-in.component').then((m) => m.PageFastSignInComponent),
        title: 'Sign In'
      },
      {
        path: 'forgot-password',
        loadComponent: () => import('./pages/pages/auth/forgot-password/forgot-password.component').then((m) => m.ForgotPasswordComponent),
        title: 'Forgot Password'
      },
      {
        path: 'payment-status/:status',
        loadComponent: () => import('./pages/pages/payment-status/payment-status.component').then((m) => m.PaymentStatusComponent),
        title: 'Payment Status'
      }
    ]
  },
  {
    path: '',
    component: CustomLayoutComponent,
    canActivate: [AuthGuard, ProfileGuard],
    children: [
      {
        path: 'apps',
        children: [
          /*{
            path: 'pages',
            loadChildren: () => import('./pages/apps/pages/page.module').then((m) => m.PageModule)
          },*/
          /*{
            path: 'users',
            loadChildren: () => import('./pages/apps/users/user.module').then((m) => m.UserModule)
          }*/
        ]
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages/member/member.module').then((m) => m.MemberModule)
      },

      {
        path: 'offer-appointments',
        title: 'Offer Appointments',
        canActivate: [IsProfessionalGuard],
        loadChildren: () => import('./pages/pages/all-offerAppointments/all-offerAppointments.module').then((m) => m.AllOfferAppointmentsModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Appointments',
        path: 'appointments',
        loadChildren: () => import('./pages/pages/appointments/appointments.module').then((m) => m.AppointmentsModule)
      },
      {
        title: 'Client Appointments',
        path: 'client-appointments',
        loadChildren: () => import('./pages/pages/client-appointments/client-appointments.module').then((m) => m.ClientAppointmentsModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Services',
        path: 'services',
        loadChildren: () => import('./pages/pages/products/products/products.module').then((m) => m.ProductsModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Posts',
        path: 'posts',
        loadChildren: () => import('./pages/pages/story-comp/stories.module').then((m) => m.StoriesModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Posts',
        path: 'stories',
        loadChildren: () => import('./pages/pages/story-comp/stories.module').then((m) => m.StoriesModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Posts Create',
        path: 'story/create',
        loadChildren: () => import('./pages/pages/story-comp/story-add-edit/story-add-edit.module').then((m) => m.StoryAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        path: 'story/edit/:uuid',
        loadChildren: () => import('./pages/pages/story-comp/story-add-edit/story-add-edit.module').then((m) => m.StoryAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Pages',
        path: 'pages',
        loadChildren: () => import('./pages/pages/page-comp/pages.module').then((m) => m.PagesModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Credits',
        path: 'credits',
        loadComponent: () => import('./pages/pages/credits/credits.component').then((m) => m.CreditsComponent)
      },
      {
        canActivate: [],
        title: 'Order View',
        path: 'orders/view/:uuid',
        loadComponent: () => import('../@hop/components/view-order/view-order.component').then((m) => m.ViewOrderComponent)
      },
      {
        canActivate: [],
        title: 'Order View by Code',
        path: 'orders/:code',
        loadComponent: () => import('../@hop/components/view-order/view-order.component').then((m) => m.ViewOrderComponent)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Orders',
        path: 'orders',
        loadChildren: () => import('./pages/pages/orders/orders.module').then((m) => m.OrdersModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Contacts',
        path: 'contacts',
        loadChildren: () => import('./pages/pages/contacts/contacts.module').then((m) => m.ContactsModule)
      },
      {
        canActivate: [],
        title: 'Client Orders',
        path: 'client-orders',
        loadChildren: () => import('./pages/pages/client-orders/client-orders.module').then((m) => m.ClientOrdersModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Page Create',
        path: 'page/create',
        loadChildren: () => import('./pages/pages/page-comp/page-add-edit/page-add-edit.module').then((m) => m.PageAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Page Edit',
        path: 'page/edit/:uuid',
        loadChildren: () => import('./pages/pages/page-comp/page-add-edit/page-add-edit.module').then((m) => m.PageAddEditModule)
      },
      {
        title: 'Reviews',
        path: 'submitted-reviews',
        loadChildren: () => import('./pages/pages/submitted-reviews/submitted-reviews.module').then((m) => m.SubmittedReviewsModule)
      },
      {
        canActivate: [],
        title: 'Received Reviews',
        path: 'received-reviews',
        loadChildren: () => import('./pages/pages/received-reviews/received-reviews.module').then((m) => m.ReceivedReviewsModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Service Create',
        path: 'service/create',
        loadChildren: () => import('./pages/pages/products/product-add-edit/product-add-edit.module').then((m) => m.ProductAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Service Edit',
        path: 'service/edit/:uuid',
        loadChildren: () => import('./pages/pages/products/product-add-edit/product-add-edit.module').then((m) => m.ProductAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Service View',
        path: 'service/view/:productUuid',
        loadChildren: () => import('./pages/pages/products/view-product/view-product.module').then((m) => m.ViewProductModule)
      },
      {
        title: 'Account',
        path: 'account',
        loadChildren: () => import('./pages/pages/account-settings/account-settings.module').then((m) => m.AccountSettingsModule)
      },

      {
        title: 'Profile',
        path: 'public-profile',
        loadChildren: () => import('./pages/pages/public-profile/public-profile.module').then((m) => m.PublicProfileModule)
      },
      // {
      //   canActivate: [IsProfessionalGuard],

      //   path: 'offer-appointment',
      //   loadChildren: () => import('./pages/pages/new-links/new-link.module').then((m) => m.NewLinkModule)
      // },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Offer Create',
        path: 'offer/create',
        loadChildren: () => import('./pages/pages/offer-add-edit/offer-add-edit.module').then((m) => m.OfferAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Offer Edit',
        path: 'offer/edit/:uuid',
        loadChildren: () => import('./pages/pages/offer-add-edit/offer-add-edit.module').then((m) => m.OfferAddEditModule)
      },
      {
        canActivate: [IsProfessionalGuard],
        title: 'Availability',
        path: 'availability',
        loadChildren: () => import('./pages/pages/availability-component/availability.module').then((m) => m.AvailabilityCompModule)
      },
      {
        title: 'Change Password',
        path: 'change-password',
        loadComponent: () =>
          import('./pages/pages/auth/change-password-user/change-password-user.component').then((m) => m.ChangePasswordUserComponent)
      }
    ]
  },

  {
    path: '',
    canActivate: [AuthGuard],
    children: [
      {
        path: 'onboard',
        loadChildren: () => import('./pages/pages/onboard/onboard.module').then((m) => m.OnboardModule),
        title: 'Onboard'
      }
    ]
  },
  {
    path: '404',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module)
  },
  {
    path: ':pageSlug',
    component: CustomLayoutComponent,
    loadChildren: () => import('./pages/pages/app-page/app-page.module').then((m) => m.AppPageModule),
    canActivate: [],
    resolve: { pageData: routeDataResolver }
  },
  {
    path: '**',
    loadChildren: () => import('./pages/pages/errors/error-404/error-404.module').then((m) => m.Error404Module)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      //preloadingStrategy: QuicklinkStrategy,
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled'
    })
  ],
  exports: [RouterModule /*QuicklinkModule*/],
  providers: [
    {
      provide: TitleStrategy,
      useClass: TemplatePageTitleStrategy
    }
  ]
})
export class AppRoutingModule {}
