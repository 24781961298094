import { FeaturesModel } from '../@hop/models';

const mainUrl = '';
const sentryUrl = 'https://7a3bb7ba7d5c4609899b6809d64ed3ee@s.cosmoslevel.com/5';
const isActivatedSentry = true;

export const environment: any = {
  name: window.location.host.replace(/www./, ''),
  production: true,
  apiUrl: mainUrl + '/api',
  apiUploadUrl: mainUrl + '/api',
  apiSocketUrl: mainUrl,
  mainUrl: mainUrl ? mainUrl : window.location.protocol + '//' + window.location.host,
  sentryUrl,
  isActivatedSentry,
  features: new FeaturesModel({
    isDebug: { value: false, payload: 'false' },
    isStripeTest: { value: false, payload: 'false' },
    isSearchHomePage: { value: false, payload: 'false' }
  }),
  orProjectKey: 'EbI4BbbbOcdGyMktWcdf',
  orIngestPoint: 'https://or.cosmoslevel.com/ingest',
  isOrActivated: true,
  twitterId: '@koschedule',
  defaultLangCode: 'en',
  appConfig: {}
};
