<div class="dropdown">
  <div class="dropdown-header flex items-center justify-between">
    <div class="flex items-center">
      <div class="flex items-center justify-center mr-4 relative">
        <img class="rounded-full w-14 h-14 justify-center m-auto"
          [src]="user?.imageFile?.url ? user?.imageFile?.url : environment.mainUrl + '/assets/img/profile.png'" />
        @if (user?.isProfessional) {
        <mat-icon class="absolute bottom-1 left-0 text-2xl text-yellow-500">star</mat-icon>
        }
      </div>
      <div class="flex flex-col">
        <p class='cursor-pointer' [routerLink]='"/@"+user?.username'>{{ user?.firstName }} {{ user?.lastName }}</p>
        <div [matMenuTriggerFor]="switchProfessionalMenu" class="cursor-pointer flex flex-row items-center">
          <p class='text-xs opacity-75 '>{{ (user?.isProfessional?'_general.professional-mode':'_general.client-mode') |
            translate }}
          </p>
          <mat-icon>arrow_drop_down</mat-icon>
        </div>
        @if (!user?.isProfessional && user?.needsPassword) {
        <a (click)="openSetPasswordDialog()" class="text-xs cursor-pointer opacity-75">{{"_authentication.set_password"
          | translate}}</a>
        }
      </div>
    </div>
    <button data-testid="button-settings" [matMenuTriggerFor]="settingsMenu" mat-icon-button matTooltip="Settings"
      matTooltipPosition="before" type="button">
      <mat-icon class="notifications-header-icon" svgIcon="mat:settings"></mat-icon>
    </button>
  </div>

  <div class="dropdown-content">
    @for (item of items; track trackById($index, item)) {
    <a (click)="close()" [routerLink]="item.route" class="notification px-4 py-2 sm:px-8 sm:py-4 flex items-center"
      matRipple>
      <mat-icon [svgIcon]="item.icon" [ngClass]="item.colorClass" class="notification-icon flex-none"></mat-icon>
      <div class="flex-auto">
        <p class='text-base'>{{ item.label }}</p>
        <div class='text-xs opacity-75'>{{ item.description }}</div>
      </div>
      <mat-icon class="notification-chevron flex-none" svgIcon="mat:chevron_right"></mat-icon>
    </a>
    }
  </div>

  <div class="dropdown-footer flex flex-row-reverse items-center justify-between">
    <!--<button [matMenuTriggerFor]="statusMenu" class="dropdown-footer-select" mat-button type="button">
    <ng-container *ngFor="let status of statuses; trackBy: trackById">
      <span *ngIf="status === activeStatus">
        <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
        <span>{{ status.label }}</span>
        <mat-icon class="dropdown-footer-select-caret" svgIcon="mat:arrow_drop_down"></mat-icon>
      </span>
    </ng-container>
  </button>-->
    <a (click)="logout()" color="primary" mat-button>{{"_authentication.logout" | translate}}</a>
  </div>
</div>

<mat-menu #statusMenu="matMenu" xPosition="before" yPosition="below">
  @for (status of statuses; track trackById($index, status)) {
  <button (click)="setStatus(status)" mat-menu-item>
    <mat-icon [ngClass]="status.colorClass" [svgIcon]="status.icon"></mat-icon>
    <span>{{ status.label }}</span>
  </button>
  }
</mat-menu>

<mat-menu #settingsMenu="matMenu" xPosition="before" yPosition="below">
  <!--<button mat-menu-item>
  <mat-icon svgIcon="mat:business"></mat-icon>
  <span>Change Address</span>
</button>-->

  <!-- <button mat-menu-item>
<mat-icon svgIcon="mat:verified_user"></mat-icon>
<span>Change Username</span>
</button>-->
  <button routerLink="/account" routerLinkActive="router-link-active" mat-menu-item>
    <mat-icon svgIcon="mat:settings"></mat-icon>
    <span>{{ 'account-settings' | translate }}</span>
  </button>
  <button routerLink="/account/billing" routerLinkActive="router-link-active" mat-menu-item>
    <mat-icon svgIcon="mat:monetization_on"></mat-icon>
    <span>{{ '_billing.billing' | translate }}</span>
  </button>
  <button routerLink="/credits" routerLinkActive="router-link-active" mat-menu-item>
    <mat-icon svgIcon="mat:data_usage"></mat-icon>
    <span>{{ '_billing.credits-usage' | translate }}</span>
  </button>
  <button routerLink="/change-password" routerLinkActive="router-link-active" mat-menu-item>
    <mat-icon svgIcon="mat:lock"></mat-icon>
    <span>{{ '_authentication.change-password' | translate }}</span>
  </button>
  @if (!user?.isProfessional) {
  <button (click)="this.switchUser(true)" mat-menu-item>
    <mat-icon>people</mat-icon>
    <span>
      {{"_authentication.switch-to-professional" | translate}}
    </span>
  </button>
  }
  @if (user?.isProfessional) {
  <button (click)="this.switchUser(false)" mat-menu-item>
    <mat-icon> people</mat-icon>
    <span>
      {{"_authentication.switch-to-client" | translate}}
    </span>
  </button>
  }
  <button data-testid="button-wizard-show-disable" (click)="this.showOrHideWizard(!appConfigService.isEnabledWizard())"
    mat-menu-item>
    <mat-icon>{{appConfigService.isEnabledWizard()? 'disabled_visible':'preview'}}</mat-icon>
    @if (appConfigService.isEnabledWizard()) {

    <span>
      {{"_wizard.disable-wizard" | translate}}
    </span>
    }
    @if (!appConfigService.isEnabledWizard()) {

    <span>
      {{"_wizard.show-wizard" | translate}}
    </span>
    }
  </button>


  <!--<button mat-menu-item>
<mat-icon svgIcon="mat:notifications_off"></mat-icon>
<span>Disable Notifications</span>
</button>-->
</mat-menu>

<mat-menu #switchProfessionalMenu="matMenu" xPosition="before" yPosition="below">
  @if (!user?.isProfessional) {
  <button (click)="this.switchUser(true)" mat-menu-item>
    <mat-icon>people</mat-icon>
    <span>
      {{"_authentication.switch-to-professional" | translate}}
    </span>
  </button>
  }
  @if (user?.isProfessional) {
  <button (click)="this.switchUser(false)" mat-menu-item>
    <mat-icon>people</mat-icon>
    <span>
      {{"_authentication.switch-to-client" | translate}}
    </span>
  </button>
  }
</mat-menu>